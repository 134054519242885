

import { syncRef, useUrlSearchParams, useVModels } from '@vueuse/core'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BarMenu from './BarMenu.vue'
import moment from 'moment'

export default defineComponent({
  components: { BarMenu },
  props: {
    name: {
      type: String,
    },
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    source: {
      type: String,
    },
    station: {
      type: String,
    },
    procedure: {
      type: String,
    },
    sort: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      from: vFrom,
      to: vTo,
      source: vSource,
      station: vStation,
      procedure: vProcedure,
      name: vName,
      sort: vSort,
    } = useVModels(props, emit)
    // const settings = ref(JSON.parse(localStorage.getItem('settings') || '{}'))

    const urlParams = useUrlSearchParams()
    onMounted(() => {
      if (urlParams.from) {
        fromVal.value = urlParams.from as string
      }
      if (urlParams.to) {
        toVal.value = urlParams.to as string
      }
      if (urlParams.source) {
        vSource.value = urlParams.source as string
      }

      if (urlParams.station) {
        vStation.value = urlParams.station as string
      }

      if (urlParams.procedure) {
        vProcedure.value = urlParams.procedure as string
      }
      if (urlParams.sort) {
        sortModel.value = urlParams.sort as string
      }
      if (urlParams.name) {
        vName.value = urlParams.name as string
      }
    })
    // for date
    const fromVal = ref('')
    const toVal = ref('')

    watch(fromVal, () => {
      vFrom.value = String(Math.floor(moment(fromVal.value).startOf('day').valueOf() / 1000))
    })
    watch(toVal, () => {
      vTo.value = String(Math.floor(moment(toVal.value).endOf('day').valueOf() / 1000))
    })

    const sortModel = ref('dateTime')
    const sortAsc = ref(false)
    const sort = computed(() => {
      let s = [] as string[]
      switch (sortModel.value) {
        case 'dateTime':
          s = ['created.seconds', 'created.nanos']
          break
        case 'duration':
          s = ['duration.seconds']
      }

      if (!sortAsc.value) {
        for (const i in s) {
          s[i] = '-' + s[i]
        }
      }

      return s.join(',')
    })
    syncRef(sort, vSort)

    const queryParams = computed(() => ({
      name: vName.value,
      sort: sortModel.value,
      from: fromVal.value,
      to: toVal.value,
      source: vSource.value,
      station: vStation.value,
      procedure: vProcedure.value,
    }))

    const router = useRouter()
    const route = useRoute()

    watch([sort, fromVal, toVal, vSource, vStation, vProcedure, vName], () => {
      router.replace({
        query: Object.fromEntries(
          Object.entries(
            Object.assign({}, route.query, queryParams.value),
          ).filter(([, v]) => !!v)),
      })
    })

    return {
      vName,
      sortModel,
      sortAsc,
      fromVal,
      toVal,
      vSource,
      vStation,
      vProcedure,
    }
  },
})
