
import { computed, defineComponent, onBeforeMount, onMounted, ref, watch } from 'vue'
import { useUsersStore } from '@/stores/users/users'
import SessionFilters from '@/components/SessionFilters.vue'
import useSessions from '@/models/list/sessions'
import useStations from '@/models/list/stations'
import useProcedures from '@/models/list/procedures'
import CardContainer from '@/components/CardContainer.vue'
import Card from '@/components/Card.vue'
import { debounce } from 'lodash'
import moment from 'moment'
import { useIntersectionObserver, useUrlSearchParams } from '@vueuse/core'

export default defineComponent({
  name: 'UnitsAnalysis',
  components: {
    SessionFilters,
    CardContainer,
    Card,
  },
  setup() {
    const { userGroup } = useUsersStore()

    const from = ref<string>()
    const to = ref<string>()
    const procedures = useProcedures()
    const stations = useStations()
    const sourceSearch = ref('')
    const stationSearch = ref('')
    const procedureSearch = ref('')
    const name = ref('')
    const sort = ref<string>('')
    const { items: sessions, fetchItems: fetchSessions, nextPage } = useSessions()
    const proceduresByName = computed(() => Object.fromEntries(
      procedures.items.value
        .map(v => [v.name, v.friendlyName]),
    ))

    const stationsByName = computed(() => Object.fromEntries(
      stations.items.value
        .map(v => [v.name, v.friendlyName]),
    ))
    const filter = computed(() => {
      const filters: unknown[] = [{
        // Calibration & Deployed mode only
        mode: { $in: [2, 3] },
      }]

      if (userGroup) {
        filters.push({
          manufacturer: { $in: userGroup },
        })
      }

      if (name.value) {
        filters.push({
          name: name.value[0] === '^'
            ? {
                $regex: name.value,
              }
            : name.value,
        })
      }
      if (from.value) {
        filters.push({ 'lastModified.seconds': { $gte: from.value } })
      }

      if (to.value) {
        filters.push({ 'lastModified.seconds': { $lte: to.value } })
      }

      if (sourceSearch.value) {
        filters.push({ source: { $regex: '.*' + sourceSearch.value + '.*' } })
      }

      if (stationSearch.value) {
        filters.push({ station: { $regex: '.*' + stationSearch.value + '.*' } })
      }

      if (procedureSearch.value) {
        filters.push({ procedure: { $regex: '.*' + procedureSearch.value + '.*' } })
      }
      return filters.length > 0
        ? JSON.stringify({
          $and: filters,
        })
        : ''
    })

    const loader = ref()
    useIntersectionObserver(
      loader.value,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          nextPage()
        }
      },
    )

    const updateSearch = debounce(async () => fetchSessions({
      pageSize: 15,
      filter: filter.value,
      orderBy: sort.value,
    }), 200)
    onBeforeMount(async () => {
      await Promise.all([
        procedures.fetchItems({ pageSize: -1 }),
        stations.fetchItems({
          pageSize: -1,
          filter: JSON.stringify({
            manufacturer: { $in: userGroup },
          }),
        }),
      ])
    })
    onMounted(async () => {
      await Promise.all([
        updateSearch(),
      ])
    })
    const urlParams = useUrlSearchParams()
    watch([filter, sort, urlParams], updateSearch)

    const fmtDuration = (duration: string | undefined) => {
      if (!duration) {
        return 'N/A'
      }
      const val = parseFloat(duration.slice(0, duration.length - 1))
      const d = moment.duration(val, 'seconds')
      const h = d.hours()
      const m = d.minutes()
      const s = d.seconds()
      const S = d.milliseconds() % 1000
      return (h ? h + 'h ' : '') +
       (m ? m + 'm ' : '') +
       (s ? s + '.' + Math.round(S / 100) + 's ' : '')
    }

    return {
      from,
      to,
      sort,
      name,
      sessions,
      procedures: proceduresByName,
      stations: stationsByName,
      moment,
      sourceSearch,
      procedureSearch,
      stationSearch,
      fmtDuration,
      loader,
    }
  },
})
